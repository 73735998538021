
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Swipe, SwipeItem, Image, ImagePreview, Row } from "vant";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Row.name]: Row,
  },
})
export default class imgDetails extends Mixins(Mixin) {
  show = true;
  index = 0;
  current = 0;
  images: Array<string> = [];
  mounted(): void {
    this.images = JSON.parse(String(this.$route.query.url));
  }
  //轮播切换
  onChange(index: number): void {
    this.current = index;
  }
  //返回
  onClickLeft(): void {
    this.$router.go(-1);
  }
}
