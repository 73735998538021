
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image } from "vant";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class PaymentHead extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public headImg!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  public isOrder!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  public commodityTitle!: string;

  @Prop({
    type: String,
    default: "",
  })
  public specs!: string;

  @Prop({
    type: String,
    default: "",
  })
  public subSpecs!: string;

  @Prop({
    type: Number,
    default: null,
  })
  public num!: number;

  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public payInfoList!: Array<order.PayInfo>;

  // @Watch("num", { immediate: true, deep: true })
  // updateNum(): void  {
  //   this.number = this.num;

  // }
  goMallDetail(): void {
    this.$emit("goMallDetail");
  }
}
