
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Popup, Cell, CellGroup, Image, Row, ImagePreview } from "vant";
import RemainStock from "./RemainStock.vue";
import { monitorEvent } from "@/utils/youMengMonitor";

declare type CommoditySpecsInfoDto = {
  /** 商品规格Id */
  specsId?: string;
  /** 商品规格名称 */
  specsName?: string;
  isShow?: boolean;
};

declare type CommoditySpecsTypeDto = {
  /** 商品规格类型信息 */
  commoditySpecsInfoDtos?: Array<CommoditySpecsInfoDto>;
  /** 商品规格类型名称 */
  specsTypeName?: string;
};

@Component({
  components: {
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Row.name]: Row,
    RemainStock,
  },
})
export default class Seen extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default: {
      show: false,
      order: {
        commodityInfoId: "",
        num: 1,
        specsRelationId: "",
        remainStock: 1,
      },
    },
  })
  public dataInfo!: {
    show: boolean;
    order: {
      commodityInfoId: string;
      num: number;
      specsRelationId: string;
      remainStock: number;
    };
  };
  @Prop({
    type: Object,
    default: () => {
      return {};
    },
  })
  public info!: good.CommodityDto;
  @Prop({
    type: String,
    default: "",
  })
  public defaultIndexPic!: string;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public lowPrice!: Array<good.SpecsMemberPriceDto>;
  @Prop({
    type: String,
    default: "01",
  })
  public status!: string; //详情页初始状态

  isShowS = true;
  selectArr: Array<string> = []; //存放被选中的值
  shopItemInfo: Record<string, good.CommoditySpecsDto> = {}; //存放要和选中的值进行匹配的数据
  subIndex: Array<number> = []; //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
  chsp: good.CommoditySpecsDto = {}; //选中的规格
  stockout = false;
  mosp: good.CommoditySpecsDto = {}; //页面显示的规格,默认是规格详情数组的第一个
  defaultPic: good.CommoditySpecsDto = {}; //默认价格信息
  list: Array<CommoditySpecsTypeDto> = []; //规格列表
  stockInfo = {
    num: 1,
    remainStock: 1,
    isShow: true,
  };
  result: Array<string> = [];
  commoditySpecsDtos: Array<good.CommoditySpecsDto> = [];
  @Watch("info", { immediate: true, deep: true })
  updateCommodityId(): void {
    if (this.info.commoditySpecsTypes) {
      this.list = this.info.commoditySpecsTypes;
    }
    if (this.info.commoditySpecs) {
      this.commoditySpecsDtos = this.info.commoditySpecs;
      this.defaultPic.specsMemberPrices = this.lowPrice; //默认详情页展示的最低价
      let arr = this.info.commoditySpecs.some((item) => {
        let remainStock = item.remainStock || 0;
        return remainStock > 0;
      });
      if (arr) {
        this.stockInfo.remainStock = 1;
      } else {
        this.stockInfo.remainStock = 0;
      }

      // if (this.info.commoditySpecsDtos[0]) {
      //   this.mosp = this.info.commoditySpecsDtos[0];
      // }
      this.chShopItemInfo();
      this.chList();
    }
  }
  get pur(): number {
    //限购
    let pur = 0;
    return this.info.limitNum ? this.info.limitNum : pur;
  }
  //图片列表
  get imgList(): Array<string> {
    let arr: Array<string> = [];
    if (this.info.commoditySpecs) {
      arr = this.info.commoditySpecs.map((item) => {
        return item.imgUrl || this.defaultIndexPic;
      });
    }
    return arr;
  }

  close(): void {
    this.dataInfo.show = false;
  }

  /*
   *处理按钮名称
   *
   */
  dealBtnName(stockout: boolean, isOutStockRegister?: boolean): string {
    let name = "立即购买";
    // isOutStockRegister: 是否开启“缺货登记”
    if (stockout) {
      name = "补货中";
    }
    return name;
  }

  /*
   *产看图片
   *
   */
  divPic(): void {
    ImagePreview({
      images: this.imgList,
      closeable: true,
    });
  }
  chspecsz(id: string, n: number, isShow: boolean, num2: number): void {
    // if(!isshow) {
    //   return
    // }
    if (this.selectArr[n] != id) {
      this.selectArr[n] = id;
      // this.subIndex[n] = num2;
      this.$set(this.subIndex, n, num2);
    } else {
      this.selectArr[n] = "";
      // this.subIndex[n] = -1; //去掉选中的颜色
      this.$set(this.subIndex, n, -1);
    }
    this.chList();
  }
  chShopItemInfo(): void {
    // 处理规格详情数组及默认显示的数据
    this.$nextTick(() => {
      this.mosp = this.commoditySpecsDtos[0];
    });
    this.commoditySpecsDtos.forEach((el: good.CommoditySpecsDto) => {
      let sr = "";
      let commoditySpecsDetails = el.commoditySpecsDetails;
      if (commoditySpecsDetails) {
        let length = commoditySpecsDetails.length;
        commoditySpecsDetails.forEach((item, index) => {
          sr += item.specsId + (index < length - 1 ? "," : "");
          this.shopItemInfo[sr] = el;
        });
      }
    });
  }
  chList(): void {
    //处理规格数据
    let option = this.list;
    let result: Array<string> = []; //定义数组储存被选中的值
    for (let i = 0; i < option.length; i++) {
      result[i] = this.selectArr[i] ? this.selectArr[i] : "";
    }
    for (let i = 0; i < option.length; i++) {
      let last = result[i]; //把选中的值存放到字符串last去
      let commoditySpecsInfoDtos = option[i].commoditySpecsInfoDtos;
      if (commoditySpecsInfoDtos) {
        commoditySpecsInfoDtos.forEach((commoditySpecsInfoDto) => {
          result[i] = commoditySpecsInfoDto.specsId || ""; //赋值，存在直接覆盖，不存在往里面添加规格id值
          commoditySpecsInfoDto.isShow = this.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
        });
      }
      result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
    }
    if (this.shopItemInfo[result.join()]) {
      this.chsp = this.shopItemInfo[result.join()] || {};
    }
    if (this.chsp) {
      this.mosp = this.chsp;
    }
    this.list = option;
    this.result = result;
    let add = result.every((item) => {
      return item != "";
    });
    if (add) {
      let memberLevelId = this.UserModule.memberLevelId;
      let isMemberGradeId = false;
      let arry: good.SpecsMemberPriceDto[] = [];
      if (this.chsp.specsMemberPrices) {
        isMemberGradeId = this.chsp.specsMemberPrices.some((item) => {
          return item.memberGradeId === memberLevelId;
        });
        if (isMemberGradeId) {
          //当有会员价格时显示会员价
          arry = this.chsp.specsMemberPrices.filter((item) => {
            return item.memberGradeId === memberLevelId;
          });
        } else {
          arry = this.chsp.specsMemberPrices.filter((item) => {
            return item.memberGradeId === "0";
          });
        }
      }
      this.defaultPic = JSON.parse(JSON.stringify(this.chsp)); //复杂数据类型里值得改变
      this.defaultPic.specsMemberPrices = arry;
      this.stockInfo.remainStock = Number(this.chsp.remainStock);
      this.stockout = Number(this.chsp.remainStock) <= 0 ? true : false;
      this.stockInfo.isShow = false;
    } else {
      this.defaultPic = {}; //没有全选中情况
      this.defaultPic.imgUrl = this.defaultIndexPic; //赋值默认数据
      this.defaultPic.specsMemberPrices = this.lowPrice;
      if (this.status === "01") {
        this.stockout = false;
      } else {
        this.stockout = true;
      }
      this.stockInfo.isShow = true;
    }
  }
  isMay(result: Array<string>): boolean {
    for (var i in result) {
      if (result[i] == "") {
        return true; //如果数组里有为空的值，那直接返回true
      }
    }
    return this.shopItemInfo[result.join()].remainStock == 0 ? false : true; //匹配选中的数据的库存，若不为空返回true
  }
  /*
   * 立即购买
   */
  btn(): void {
    if (this.stockout) {
      //缺货但没有开启缺货提示
      return;
    }
    if (!this.checkF()) {
      //校验是否选择了全部规格
      return;
    }
    // if (this.stockout) {
    //   this.OutStock();
    // } else {
    this.getSpecs();
    // }
  }

  checkF(): boolean {
    //校验是否选择了全部规格
    let result = this.result;
    for (let i = 0; i < result.length; i++) {
      if (!result[i]) {
        this.$toast(`请选择${this.list[i].specsTypeName}`);
        return false;
      }
    }
    return true;
  }
  /*
   * 提交信息
   */
  getSpecs(): void {
    let data: order.GoodAdvanceReq = {
      commodityInfoId: Number(this.$route.query.commodityId),
      num: this.stockInfo.num,
      specsRelationId: this.chsp.specsRelationId,
    };
    let Specification = ""; //埋点的规格信息
    if (
      this.chsp.commoditySpecsDetails &&
      this.chsp.commoditySpecsDetails.length > 0
    ) {
      let arr = this.chsp.commoditySpecsDetails;
      arr.forEach((item) => {
        let specsTypeName = item.specsTypeName || "";
        let specsName = item.specsName || "";
        Specification = specsTypeName + ":" + specsName + ";" + Specification;
      });
    }
    let activityOrderId = this.$route.query.activityOrderId
      ? String(this.$route.query.activityOrderId)
      : "";
    this.$api.orderApi.goodOrder.createGoodAdvance(
      data,
      ({ data }) => {
        //埋点：商品详情，点击立即购买
        monitorEvent("GoodDetail_ClickBuy", "点击立即购买", Specification);
        this.$router.push(
          `/mall/confirm-order?uuid=${data}&activityOrderId=${activityOrderId}`
        );
      },
      (error) => {
        error && this.$toast(error);
      }
    );
  }
  /*
   * 缺货登记
   */
  OutStock(): void {
    let commodityInfoId = this.$route.query.commodityId;
    let specsRelationId = this.chsp.specsRelationId;
    this.$api.memberApi.record.userLackCommodityRecord(
      Number(commodityInfoId),
      specsRelationId!,
      ({ data }) => {
        this.$toast("提交成功");
      },
      (error) => {
        error && this.$toast(error);
      }
    );
  }
}
