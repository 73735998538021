
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image, Icon } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
    ItemTitle,
  },
})
export default class ConversionCode extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  public isBorder!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  public exchangeUrl!: string;
  value = "https://app.mockplus.cn/team/kzkkykymue";
  isCourier = true;
  copyShaneUrl(): void {
    var input = document.createElement("input"); // 直接构建input
    input.value = this.value; // 设置内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    document.execCommand("Copy"); // 执行复制
    document.body.removeChild(input); // 删除临时实例
  }
}
