
import { Component, Mixins, Prop, Emit } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image, Icon, Popup } from "vant";
import ItemTitle from "./ItemTitle.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    ItemTitle,
  },
})
export default class PickupWay extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: false,
  })
  public isBorder!: boolean;
  @Prop({
    type: String,
    default: "",
  })
  public changeAddress!: string;
  @Prop({
    type: Boolean,
  })
  public isClick!: boolean;
  @Prop({
    type: Number,
    default: 2,
  })
  public type!: number;
  @Prop({
    type: String,
    default: "",
  })
  public commodityInfoId!: string;
  @Prop({
    type: String,
    default: "",
  })
  public showId!: string;
  @Prop({
    type: String,
    default: "",
  })
  public pickUpAddres!: string;
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public newAddrDistributionScope!: Array<string>;
  isShow = false;
  packageList = [
    { name: "现场取货", id: "01" },
    { name: "快递配送", id: "02" },
  ];

  isCourier = true;
  value = "0";
  mounted(): void {
    if (this.changeAddress.includes(",")) {
      this.value = this.changeAddress.split(",")[0];
    } else {
      this.value = this.changeAddress;
    }
  }
  activePackage(id: string): void | boolean {
    this.value = id;
    this.$emit("onChoose", id);
  }
}
